import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import NavBar from "./component/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import Dashboard from "./page/Dashboard";
import NotFound from "./page/NotFound";
import Result from "./page/Result";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<Home />} index />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/result/:id" element={<Result />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

export default App;
