import axios from "axios";
import { ApiUrl } from ".";

interface fetchOrgResult {
    Items: Array<{ CauseName: string; id: string }>;
}

export const fetchOrg = (query: string) => {
    return axios.get<fetchOrgResult>(
        `${ApiUrl}/poc/search?org=${encodeURIComponent(query)}`
    );
};
