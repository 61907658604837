import { Text, Flex } from "@chakra-ui/react";
import * as React from "react";

export const IconText: React.FC<{ icon: React.ReactNode; text: string }> = ({
    text,
    icon,
}) => {
    return (
        <Flex wrap="nowrap" columnGap={2} align="center">
            {icon}
            <Text marginLeft={1} fontWeight="bold">{text}</Text>
        </Flex>
    );
};
