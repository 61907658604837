import axios from "axios";
import { ApiUrl } from ".";

interface UploadGetQueryString {
    extension: "jpg" | "png" | "jpeg" | string;
}

export interface FetchUploadResult {
    url: string;
    fields: {
        acl: string;
        "Content-Disposition": string;
        success_action_status: string;
        bucket: string;
        "X-Amz-Algorithm": string;
        "X-Amz-Credential": string;
        "X-Amz-Date": string;
        "X-Amz-Security-Token": string;
        key: string;
        Policy: string;
        "X-Amz-Signature": string;
        "x-amz-meta-link-id": string;
    };
}
export const uploadFile = async (params: UploadGetQueryString, file: File) => {
    // Send request to generate presigned url
    const result = await axios.get<FetchUploadResult>(`${ApiUrl}/poc/upload`, {
        params,
    });

    if (result.status === 200) {
        // If presigned url is generated succesfully, create form data and append data from presigned url
        const formData = new FormData();
        for (const [key, value] of Object.entries(result.data.fields)) {
            formData.append(key, value);
        }
        // Order matters here, file must always be last
        formData.append("file", file);
        await axios.post(result.data.url, formData);
    }
    return result.data.fields.key;
};
