import { Flex, Heading, Image, VStack, Text, Center, Divider } from "@chakra-ui/react"
import React from "react"

export const MeetOurTeam = () => {

    const profiles = ["jade-profile.jpg", "kamesh-profile.jpg", "long-profile.jpg", "danny-profile.jpg", "jason-profile.jpg"];

    const record: Record<string, { name: string, role: string, link: string }> = {
        "jade-profile.jpg": {
            name: "Jade Fjestad",
            role: "AI Developer",
            link: "https://www.linkedin.com/in/jade-fjestad-607904193/",
        },
        "jason-profile.jpg": {
            name: "Long Ta",
            role: "Product Manager",
            link: "https://www.linkedin.com/in/long-viet-ta/",
        },
        "kamesh-profile.jpg": {
            name: "Kameshwara Sekar",
            role: "Team Leader",
            link: "https://www.linkedin.com/in/kameshwara-sekar-995592192/",
        },
        "long-profile.jpg": {
            name: "Long Tran",
            role: "Backend Developer",
            link: "https://www.linkedin.com/in/long-ht/",
        },
        "danny-profile.jpg": {
            name: "Danny Xue",
            role: "Frontend Developer",
            link: "https://www.linkedin.com/in/junhao-xue/",
        }
    };

    return <VStack>
        <Heading size="2xl" pb={2} color={"#A46AF4"}>Meet our team</Heading>
        <Divider borderWidth={1} borderColor={"#A46AF4"} maxW={"md"}/>
        <Flex pt={8} flexDir={["column", "row"]} justifyContent={"space-between"} justifyItems={"center"} justify={"center"} flexWrap={"wrap"} flex={"0 1 auto"}>
            {profiles.map(name => <Center key={name} display={"inline-block"} w={["full", "33.3%"]} m={"auto"} pb={4}>
                <Image src={name} w={200} h={200} rounded={"full"} m="auto" onClick={() => window.open(
                    record[name].link,
                    '_blank' // <- This is what makes it open in a new window.
                )} />
                <Text fontWeight={"semibold"} pt={2}>{record[name].name}</Text>
                <Text color={"blue.400"} fontWeight={"semibold"}>{record[name].role}</Text>
            </Center>)}
        </Flex>
    </VStack>
}