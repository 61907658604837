import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
import * as React from "react";

const NotFound: React.FC = () => {
    return (
        <Alert status="error">
            <AlertIcon />
            <AlertTitle>Page Not Found</AlertTitle>
            <AlertDescription>This page does not exist</AlertDescription>
        </Alert>
    );
};

export default NotFound;
