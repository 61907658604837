import { Heading, VStack, Text, useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { Divider } from '@chakra-ui/react'
export const Demo = () => {

    const videoWidth = useBreakpointValue([300, 560]);
    const videoHeight = useBreakpointValue([200, 315]);
    return <VStack pb={8}>
        <Heading size={"2xl"} pb={2} color={"#A46AF4"}>
            Product Demo
        </Heading>
        
        <Divider borderWidth={1} borderColor={"#A46AF4"} maxW={"md"}/>
        <Text color={"gray.300"} pb={2} fontSize={"xl"} >
            Check out the following video for the demo of our product!
        </Text>
        <iframe width={videoWidth} height={videoHeight} src="https://www.youtube.com/embed/D47rwnffQdg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>
        </iframe>
    </VStack>
}