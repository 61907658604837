import React, { useState } from "react";
import {
    Center,
    IconButton,
    Image,
    Portal,
    Tooltip,
    useColorModeValue,
} from "@chakra-ui/react";
import { MdOpenInFull, MdCloseFullscreen } from "react-icons/md";

export interface ImageViewPortProps {
    src: string;
}

export const ImageViewPort: React.VFC<ImageViewPortProps> = (props) => {
    const [fullScreenImage, setImageFullScreen] = useState<boolean>(
        () => false
    );

    const setImageFullScreenTrue = () => setImageFullScreen(true);
    const setImageFullScreenFalse = () => setImageFullScreen(false);

    const backgroundColor = useColorModeValue("gray.200", "black");

    if (fullScreenImage)
        return (
            <Portal>
                <Center
                    position="absolute"
                    left={0}
                    top={0}
                    background={backgroundColor}
                    w={"100vw"}
                    h={"100vh"}
                >
                    <Image
                        src={props.src}
                        objectFit="contain"
                        maxW={"100vw"}
                        maxH={"100vh"}
                    />
                    <Tooltip label="Exit full-screen">
                        <IconButton
                            top={5}
                            right={5}
                            position="absolute"
                            variant="ghost"
                            size={"md"}
                            background={"rgba(0, 0, 0, 0.4)"}
                            borderRadius={"50%"}
                            aria-label={"Exit full-screen"}
                            onClick={setImageFullScreenFalse}
                            icon={<MdCloseFullscreen color="white" />}
                        />
                    </Tooltip>
                </Center>
            </Portal>
        );

    return (
        <Center
            bg={backgroundColor}
            w="100%"
            onClick={setImageFullScreenTrue}
            position="relative"
        >
            <Image
                src={props.src}
                maxH={"70vh"} // MaxH scroll that user don't need to scroll
                objectFit="contain"
                minH={"30vh"}
            />
            <Tooltip label="Enter full-screen">
                <IconButton
                    position={"absolute"}
                    variant="ghost"
                    size={"md"}
                    background={"rgba(0, 0, 0, 0.4)"}
                    top={2}
                    borderRadius={"50%"}
                    right={2}
                    aria-label={"Enter full-screen"}
                    icon={<MdOpenInFull color="white" />}
                />
            </Tooltip>
        </Center>
    );
};
