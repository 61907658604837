import {
    Box,
    Card,
    Heading,
    Input,
    SimpleGrid,
    Grid,
    GridItem,
    CardBody,
    Link,
    Text,
    Image,
    Button,
    Select,
} from "@chakra-ui/react";
import * as React from "react";
import { CopyIcon, CheckIcon } from "@chakra-ui/icons";
import PreviewImage from "../component/preview.png";
import { submitRequest } from "../api/approve";
import { useNavigate } from "react-router-dom";
import { AsyncSelect } from "chakra-react-select";
import { SubmitRequestParam } from "../api/approve";
import { fetchOrg } from "../api/search";

const Details: React.FC<{ encryptedFileName: string; fileName: string }> = ({
    encryptedFileName,
    fileName,
}) => {
    const [userInput, setUserInput] = React.useState<SubmitRequestParam>({
        PER: "",
        MON: "",
        ORG: "",
        ADD: "",
        CON: "",
        TAX: "",
        DAT: "",
        org_id: "",
        file_name: "",
    });
    const navigate = useNavigate();

    return (
        <Box bgColor={"gray.800"} padding={5} borderRadius="2xl" minW={700}>
            <Heading size="md">Details</Heading>
            <SimpleGrid
                templateColumns={"50% 40%"}
                spacingX={10}
                spacingY={5}
                fontSize={[10, 10, 12, 15]}
                mt={5}
            >
                <Grid templateColumns={"50% 50%"} rowGap={5} columnGap={2}>
                    <GridItem colSpan={2}>
                        <Input
                            placeholder="Name"
                            value={userInput.PER}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    PER: e.target.value,
                                }))
                            }
                        />
                    </GridItem>
                    <GridItem colSpan={2}>
                        <AsyncSelect
                            variant="outline"
                            placeholder="Organization"
                            onChange={(e: any) => {
                                setUserInput((prev) => ({
                                    ...prev,
                                    org_id: e.value,
                                    ORG: e.label,
                                }));
                            }}
                            loadOptions={(input) => {
                                if (input) {
                                    return fetchOrg(input).then((res) =>
                                        res.data.Items.map((org) => ({
                                            value: org.id,
                                            label: org.CauseName,
                                        }))
                                    );
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Input
                            placeholder="Address"
                            value={userInput.ADD}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    ADD: e.target.value,
                                }))
                            }
                        />
                    </GridItem>
                    <GridItem>
                        <Input
                            placeholder="Amount"
                            type="number"
                            value={userInput.MON}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    MON: e.target.value,
                                }))
                            }
                        />
                    </GridItem>
                    <GridItem>
                        <Input
                            placeholder="Tax Number"
                            value={userInput.TAX}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    TAX: e.target.value,
                                }))
                            }
                        />
                    </GridItem>
                    <GridItem>
                        <Input
                            type={"date"}
                            value={userInput.DAT}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    DAT: e.target.value,
                                }))
                            }
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            placeholder="Country"
                            value={userInput.CON}
                            onChange={(e) =>
                                setUserInput((prev) => ({
                                    ...prev,
                                    CON: e.target.value,
                                }))
                            }
                        >
                            <option value="Canada">Canada</option>
                            <option value="United States">United States</option>
                        </Select>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Button
                            rightIcon={<CheckIcon />}
                            bgColor="green.500"
                            color="white"
                            width={"100%"}
                            onClick={() => {
                                submitRequest({
                                    ...userInput,
                                    DAT:
                                        userInput.DAT.match(/\d+/g)
                                            ?.reverse()
                                            .join("/") || "",
                                    file_name: encryptedFileName,
                                });
                                navigate(`/result/${encryptedFileName}`);
                            }}
                        >
                            Confirm
                        </Button>
                    </GridItem>
                </Grid>
                <Card overflow="hidden">
                    <Image src={PreviewImage} shadow="2xl" />
                    <CardBody>
                        <Text>File Link</Text>
                        <Link color="blue.500">
                            https://localhost:3000/result/{encryptedFileName}
                        </Link>
                        <CopyIcon ml={5} boxSize={5} />
                        <Text>File Name</Text>
                        <Text>{fileName}</Text>
                    </CardBody>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default Details;
