import * as React from "react";
import { Heading, Flex, Button, Box, Link } from "@chakra-ui/react";

const NavBar: React.FC = () => {
    return (
        <Box bgColor="#0B0E11">
            <Flex justify={"space-between"} p={5} maxW={"8xl"} mx={"auto"}>
                <Heading>ZoidAI</Heading>
                <Flex gap={4}>
                    <Link href="/" _hover={{ textDecor: "none" }}>
                        <Button variant={"outline"}>Home</Button>
                    </Link>
                    <Link href="/dashboard" _hover={{ textDecor: "none" }}>
                        <Button variant={"outline"}>Dashboard</Button>
                    </Link>
                </Flex>
            </Flex>
        </Box>
    );
};

export default NavBar;
