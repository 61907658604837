import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
};

const styles = {
    global: {
        body: {
            bg: "black",
        },
    },
};

const theme = extendTheme(
    { config, styles },
    withDefaultColorScheme({
        colorScheme: "purple",
        components: ["Button", "Tabs"],
    })
);

export default theme;
