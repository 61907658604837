import * as React from "react";
import { ImageUpload } from "../component/FileUploader";
import { Heading, Stack } from "@chakra-ui/react";
import { uploadFile } from "../api/upload";
import Details from "./Details";

const Dashboard: React.FC = () => {
    const [step, setStep] = React.useState(0);
    const encryptedName = React.useRef("");
    const fileName = React.useRef("");

    return (
        <Stack py={5} maxW={"7xl"} mx="auto">
            <Heading size={"lg"} display="inline-block" color={"#A46AF4"}>
                Dashboard
            </Heading>
            <Stack
                direction={["column", "column", "row"]}
                justifyContent={"center"}
                gap={5}
                pt={2}
            >
                {step === 0 ? (
                    <ImageUpload
                    
                        onDropAccepted={async (file) => {
                            fileName.current = file[0].name;
                            const fileType = file[0].type.split("/");
                            const encryptedFileName = await uploadFile(
                                {
                                    extension: fileType[fileType.length - 1],
                                },
                                file[0]
                            );
                            encryptedName.current = encryptedFileName.substring(
                                "doc/".length
                            );
                            setStep(1);
                        }}
                    />
                ) : (
                    <Details
                        encryptedFileName={encryptedName.current}
                        fileName={fileName.current}
                    />
                )}

            </Stack>
        </Stack>
    );
};

export default Dashboard;
