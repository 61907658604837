// import {
//     ArrowLeftIcon,
//     ArrowRightIcon,
//     ChevronLeftIcon,
//     ChevronRightIcon,
// } from "@chakra-ui/icons";
import {
    // Text,
    // Flex,
    // IconButton,
    // Tooltip,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Box,
} from "@chakra-ui/react";
import React from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";

type Label = { name: string; confidence: number };

type LabelColumn = Column<Label>[];

export interface LabelTableProps {
    label: Label[];
}

export const ResultTable = (props: LabelTableProps) => {
    const data = React.useMemo<Label[]>(
        () =>
            props.label.map((val) => {
                return {
                    name: val.name,
                    confidence: Math.round(val.confidence),
                };
            }),
        [props.label]
    );

    const columns = React.useMemo<LabelColumn>(
        () => [
            {
                Header: "Label",
                accessor: "name",
                disableSortBy: true,
            },
            {
                Header: "Confidence (%)",
                accessor: "confidence",
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        page,
        headerGroups,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            disableSortRemove: true,
            initialState: {
                pageSize: 6,
            },
        },
        useSortBy,
        usePagination
    );

    return (
        <Box>
            <Table
                variant={"simple"}
                colorScheme="facebook"
                {...getTableProps()}
                minH="40vh"
            >
                <Thead>
                    {headerGroups.map((headerGroup, idx) => (
                        // eslint-disable-next-line
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // eslint-disable-next-line
                                <Th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {/* <Flex justifyContent={"space-evenly"} alignItems="center" mt="3">
                <Tooltip label="Previous Page">
                    <IconButton
                        aria-label="Previous Page"
                        onClick={() => gotoPage(0)}
                        isDisabled={!canPreviousPage}
                        icon={<ArrowLeftIcon h={3} w={3} />}
                        ml={4}
                    />
                </Tooltip>
                <Tooltip label="Previous Page">
                    <IconButton
                        aria-label="Previous Page"
                        onClick={previousPage}
                        isDisabled={!canPreviousPage}
                        icon={<ChevronLeftIcon h={6} w={6} />}
                    />
                </Tooltip>

                <Text>
                    {pageIndex + 1}/{pageCount}
                </Text>
                <Tooltip label="Next Page">
                    <IconButton
                        aria-label="Next Page"
                        onClick={nextPage}
                        isDisabled={!canNextPage}
                        icon={<ChevronRightIcon h={6} w={6} />}
                    />
                </Tooltip>
                <Tooltip label="Last Page">
                    <IconButton
                        aria-label="Next Page"
                        onClick={() => gotoPage(pageCount - 1)}
                        isDisabled={!canNextPage}
                        icon={<ArrowRightIcon h={3} w={3} />}
                        ml={4}
                    />
                </Tooltip>
            </Flex> */}
        </Box>
    );
};
