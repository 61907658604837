import {
    Box,
    BoxProps,
    VStack,
    Text,
    Button,
    AlertIcon,
    AlertDescription,
    Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DropEvent, useDropzone } from "react-dropzone";
import { Alert } from "@chakra-ui/react";
import React from "react";
import UploadImage from "./file_upload.webp";

export interface ImageUploadProps extends BoxProps {
    onDropAccepted: (files: File[], dropEvent: DropEvent) => void;
}

export const AlertNotification = ({
    alertDescription,
}: {
    alertDescription: string;
}) => {
    return (
        <Alert
            status="error"
            position="fixed"
            bottom={20}
            right={{ base: 0, md: 2 }}
            width={{ base: "full", md: "fit-content" }}
            bg={"red.600"}
            borderRadius="5"
        >
            <AlertIcon />
            <AlertDescription>{alertDescription}</AlertDescription>
        </Alert>
    );
};

export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
    const [alertTrigger, setAlertTrigger] = useState(false);

    useEffect(() => {
        if (alertTrigger) {
            const timeout = setTimeout(() => setAlertTrigger(false), 4000);
            return () => clearTimeout(timeout);
        }
        return;
    }, [alertTrigger]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            "image/png": [".jpg", ".png", ".jpeg"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
        },
        maxFiles: 1,
        multiple: false,
        noClick: true,
        maxSize: 4000000,
        onDropRejected: () => {
            // To prolong message error for subsequent file upload
            // If after the first failed upload, the user upload the 2nd failed file,
            // we make sure that the alert prolongs for at least 4 second for the 2nd time
            setAlertTrigger(false);
            setAlertTrigger(true);
        },
        onDropAccepted: props.onDropAccepted,
    });

    const { onDropAccepted, ...boxProps } = props;
    return (
        <Box {...boxProps}>
            {alertTrigger ? (
                <AlertNotification alertDescription="Must be a JPG, PNG, JPEG, PDF or DOC(X) file and its size must be smaller than 4MiB " />
            ) : null}
            <VStack
                p={10}
                cursor="pointer"
                bgColor={"gray.800"}
                {...getRootProps({})}
                border="0px solid"
                borderRadius="2xl"
            >
                <input {...getInputProps()} />
                <Image
                    src={UploadImage}
                    w="75%"
                    htmlHeight={"450"}
                    htmlWidth={"643"}
                />
                <Text fontWeight={500}>Drop Image Here To Upload</Text>

                <Text fontWeight={"normal"}>Or</Text>
                <Button variant="solid" colorScheme={"purple"} onClick={open}>
                    Browse Your File
                </Button>
            </VStack>
        </Box>
    );
};
