import { Center, Heading, Image, Stack, VStack, Text, Divider, } from "@chakra-ui/react"
import React from "react"

export const Sponsor = () => {
    return <Center pb={8}>
        <VStack>
            <Heading size={"2xl"} pb={2} color={"#A46AF4"}>Sponsors</Heading>
            <Divider borderWidth={1} borderColor={"#A46AF4"} maxW={"md"}/>
            <Text pb={5} color={"gray.300"} fontSize={"xl"}>We cannot make this happen without the help of our sponsors!</Text>
            <Stack direction={["column", "row"]} justifyContent={"space-around"} px={5} spacing={10}>
                <Image src="UC-horz-rgb-white.svg" maxW={["340","500"]} objectFit={"contain"} />
                <Image src="Benevity_logo.svg.png" maxW={["340","500"]} objectFit={"contain"} />
            </Stack>
        </VStack>
    </Center>
}