import { CheckIcon, CloseIcon, WarningIcon } from "@chakra-ui/icons";
import {
    Box,
    Center,
    Flex,
    Heading,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,

} from "@chakra-ui/react";
import React from "react";
import { IconText } from "../component/IconText";
import { ImageViewPort } from "../component/ImageViewPort";
import { ResultTable } from "../component/ResultTable";
import { useStatus } from "../api/status";
import { useParams } from "react-router-dom";

export const DisplayStatus = ({ status }: { status: string }) => {
    if (status === "Failed")
        return <IconText
            icon={<CloseIcon color="red.500" />}
            text={"Pending Human Review"}
        />

    if (status === "Succeeded")
        return <IconText
            icon={<CheckIcon color="green.500" />}
            text={"Succeeded"}
        />
    if (status === "Pending")
        return <IconText
            icon={<WarningIcon color="orange.500" />}
            text={"Verification successful, Pending Human review"}
        />
    return <>{status}</>
}
const Result: React.FC = () => {
    const status = useStatus(useParams().id!);
    return !status.isSuccess ? (
        <Center>
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                mt={200}
            />
        </Center>
    ) : (
        <Stack p={5} maxW={"7xl"} mx="auto">
            <Heading size={"lg"} display="inline-block" pb={"20px"}>
                Result
            </Heading>
            <Stack
                direction={["column", "column", "row"]}
                justifyContent={"space-around"}
            >
                <Box
                    bgColor={"gray.800"}
                    padding={"20px"}
                    gap={"15px"}
                    border="0px solid"
                    borderRadius="2xl"
                    height={"fit-content"}
                    width={"50%"}
                >
                    <Heading size={"md"} display="inline-block" pb={"15px"}>
                        Document Preview
                    </Heading>
                    <ImageViewPort src={status.data!.data.url} />
                </Box>

                <Flex
                    bgColor={"gray.800"}
                    direction="column"
                    padding={"20px"}
                    gap={"15px"}
                    border="0px solid"
                    borderRadius="2xl"
                    height="fit-content"
                >
                    <Box>
                        <Heading size={"md"} mb={3}>
                            Status
                        </Heading>
                        {/* <Flex gap={"90px"} pt={"10px"} justifyContent="center"> */}
                        {/* <IconText
                                icon={<InfoIcon />}
                                text="Min Confidence: 40%"
                            /> */}
                        <DisplayStatus status={status.data.data.status}/>
                        {/* </Flex> */}
                        {status.data.data.status === "Failed" && (
                            <Box borderRadius={10} overflow="hidden" mt={3}>
                                {status.data.data.feedback_messages.map(
                                    (message: string) => (
                                        <Alert status="error" maxW={600} >
                                            <AlertIcon />
                                            <AlertDescription>
                                                {message}
                                            </AlertDescription>
                                        </Alert>
                                    )
                                )}
                            </Box>
                        )}
                    </Box>

                    {/* <Box>
                        <Heading size={"md"} display="inline-block">
                            Confidence Level
                        </Heading>
                        <Center pt={"10px"}>
                            <CircularProgress
                                value={60}
                                size="100%"
                                color="#DBC033"
                            >
                                <CircularProgressLabel fontSize={"2em"}>
                                    60%
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Center>
                    </Box> */}

                    <Box>
                        <Heading size={"md"} display="inline-block" mb={3}>
                            Label Insight
                        </Heading>
                        <ResultTable
                            label={[
                                {
                                    confidence: status.data!.data.address_score,
                                    name: "Address",
                                },
                                {
                                    confidence: status.data!.data.country_score,
                                    name: "Country",
                                },
                                {
                                    confidence: status.data!.data.date_score,
                                    name: "Date",
                                },
                                {
                                    confidence: status.data!.data.money_score,
                                    name: "Money",
                                },
                                {
                                    confidence: status.data!.data.name_score,
                                    name: "Name",
                                },
                                {
                                    confidence: status.data!.data.org_score,
                                    name: "Organization",
                                },
                            ]}
                        />
                    </Box>
                </Flex>
            </Stack>
        </Stack>
    );
};

export default Result;
