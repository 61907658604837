import axios from "axios";
import { ApiUrl } from ".";

interface SubmitRequestResult {
    PER: string;
    DAT: string;
    MON: string;
    ADD: string;
    CON: string;
    TAX: string;
    ORG: string;
    status: string;
    uuid: string;
    message: string[];
}

export interface SubmitRequestParam {
    PER: string;
    DAT: string;
    MON: string;
    ADD: string;
    CON: string;
    TAX: string;
    ORG: string;
    file_name: string;
    org_id: string;
}

export const submitRequest = async (params: SubmitRequestParam) => {
    return await axios.post<SubmitRequestResult>(
        `${ApiUrl}/poc/approve`,
        params
    );
};
