import { useQuery } from "react-query";
import axios from "axios";
import { ApiUrl } from ".";

export const useStatus = (fileName: string) =>
    useQuery(
        "get status",
        async () =>
            await axios.get(`${ApiUrl}/poc/status`, {
                params: { file_name: fileName },
            }),
        { retry: true, retryDelay: 2000 }
    );
