import { Center, VStack } from "@chakra-ui/react";
import * as React from "react";
import { CTA } from "../component/cta"
import { Sponsor } from "../component/Sponsor"
import { Demo } from "../component/Demo"
import { MeetOurTeam } from "../component/MeetOurTeam";
import { FeatureProduct } from "../component/Features";
const Home: React.FC = () => {
    return (
        <Center textAlign={"center"} h="full">
            <VStack>
                {/* <Heading size={["xl", "3xl"]} p={20} bgGradient='linear(to-l, #D6BCFA, #A46AF4)' bgClip={"text"}>
                    Welcome to AI Match Request
                </Heading>
                <Button size={"lg"} variant={"solid"} onClick={goToDashBoard}>Get Started</Button> */}

                <CTA />
                <FeatureProduct />
                <Demo />
                <Sponsor />
                <MeetOurTeam />
            </VStack>
        </Center>
    );
};

export default Home;
